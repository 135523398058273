"use client";

import Logo from "@/components/icons/Logo";
import {
	LockClosedIcon,
	EyeIcon,
	EyeSlashIcon,
	XMarkIcon,
} from "@heroicons/react/24/solid";
import Link from "next/link";
import { useState, useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { createSupabaseClient } from "@/app/lib/databaseUtils";
import { ROUTES } from "@/app/lib/routes";
import CustomLink from "@/components/ui/CustomLink";

const supabase = createSupabaseClient();

export default function LoginPageContent() {
	const [error, setError] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const searchParams = useSearchParams();
	const router = useRouter();

	useEffect(() => {
		const errorParam = searchParams.get("error");
		if (errorParam) {
			let errorMessage = "";
			switch (errorParam) {
				case "session_expired":
					errorMessage = "Your session has expired. Please log in again.";
					break;
				case "invalid_credentials":
					errorMessage = "Invalid email or password. Please try again.";
					break;
				default:
					errorMessage = "An unknown error occurred. Please try again.";
			}
			setError(errorMessage);
		}
	}, [searchParams]);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);
		setError(null);

		try {
			const response = await fetch("/api/auth/login", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email, password }),
			});

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.error || "Login failed");
			}

			// On success, redirect to the dashboard
			router.push(ROUTES.DASHBOARD);
		} catch (err: any) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
			<div className="w-full max-w-md space-y-6">
				<div>
					<center>
						<Logo />
					</center>
					<h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
						Sign in to your account
					</h2>
				</div>

				{error && (
					<div className="relative bg-fire-red-100 border border-fire-red-400 text-fire-red-700 px-4 py-3 rounded">
						<span className="block sm:inline">{error}</span>
						<button
							type="button"
							className="absolute top-0 bottom-0 right-0 px-4 py-3"
							onClick={() => setError(null)}
						>
							<XMarkIcon
								className="h-5 w-5 text-fire-red-700"
								aria-hidden="true"
							/>
						</button>
					</div>
				)}

				<form
					onSubmit={handleSubmit}
					className="space-y-6 bg-white p-8 rounded-lg shadow-md"
				>
					<div className="-space-y-px rounded-md shadow-sm">
						<div>
							<label htmlFor="email-address" className="sr-only">
								Email address
							</label>
							<input
								id="email-address"
								name="email"
								type="email"
								autoComplete="email"
								required
								className="relative block w-full appearance-none border border-gray-300 px-3 py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-fire-red-500 focus:outline-none focus:ring-fire-red-500 sm:text-base"
								placeholder="Email address"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</div>
						<div className="relative">
							<label htmlFor="password" className="sr-only">
								Password
							</label>
							<input
								id="password"
								name="password"
								type={passwordVisible ? "text" : "password"}
								autoComplete="current-password"
								required
								className="relative block w-full appearance-none border border-gray-300 px-3 py-4 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-fire-red-500 focus:outline-none focus:ring-fire-red-500 sm:text-base"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<button
								type="button"
								className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
								onClick={() => setPasswordVisible(!passwordVisible)}
							>
								{passwordVisible ? (
									<EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
								) : (
									<EyeIcon className="h-5 w-5" aria-hidden="true" />
								)}
							</button>
						</div>
					</div>

					<div className="space-y-4">
						{/* Remember Me */}
						<div className="flex items-center">
							<input
								id="remember-me"
								name="remember-me"
								type="checkbox"
								className="h-4 w-4 rounded border-gray-300 text-fire-red-600 focus:ring-fire-red-500"
							/>
							<label
								htmlFor="remember-me"
								className="ml-2 block text-sm text-gray-900"
							>
								Remember me
							</label>
						</div>

						{/* Links Section */}
						<div className="flex flex-col items-start space-y-2 sm:flex-row sm:items-center sm:justify-between sm:space-y-0 sm:space-x-4 text-sm">
							<Link
								href="/forgot-password"
								className="font-medium text-fire-red-600 hover:text-fire-red-500"
							>
								Forgot your password?
							</Link>
							<Link
								href="/magic-link"
								className="font-medium text-fire-red-600 hover:text-fire-red-500"
							>
								Login with Magic Link
							</Link>
						</div>
					</div>

					<div>
						<button
							type="submit"
							disabled={loading}
							className={`group relative flex w-full justify-center rounded-md border border-transparent ${
								loading ? "bg-gray-400" : "bg-fire-red hover:bg-fire-red-700"
							} py-2 px-4 text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-fire-red-500 focus:ring-offset-2`}
						>
							<span className="absolute inset-y-0 left-0 flex items-center pl-3">
								<LockClosedIcon
									className="h-5 w-5 text-white group-hover:text-fire-red-300"
									aria-hidden="true"
								/>
							</span>
							{loading ? "Logging in..." : "Log in"}
						</button>
					</div>

					<div className="text-center">
						<p className="text-sm text-gray-600">Don't have an account?</p>
						<Link
							href="/signup"
							className="w-full mt-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-fire-red-600 bg-white hover:text-fire-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fire-red-500"
						>
							Sign up
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}

import {
	createClient,
	type SupabaseClient,
	type SupabaseClientOptions,
} from "@supabase/supabase-js";

const supabaseOptions: SupabaseClientOptions<"public"> = {
	auth: {
		autoRefreshToken: true,
		persistSession: true,
		detectSessionInUrl: true,
	},
};

// Helper function to create the Supabase client
export function createSupabaseClient(): SupabaseClient {
	const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL!;
	const supabaseAnonKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!;

	if (!supabaseUrl || !supabaseAnonKey) {
		throw new Error("Missing Supabase environment variables");
	}

	return createClient(supabaseUrl, supabaseAnonKey, supabaseOptions);
}

export function createAdminSupabaseClient() {
	const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
	const serviceRoleKey = process.env.SUPABASE_SERVICE_ROLE_KEY1;

	if (!supabaseUrl || !serviceRoleKey) {
		throw new Error(
			"Supabase URL and Service Role Key must be set in environment variables.",
		);
	}

	return createClient(supabaseUrl, serviceRoleKey);
}
